<template>
  <div>
    <div v-if="content.messageType == 6">
      <VueSlickCarousel v-bind="slickOptions">
        <div
          v-for="(content, i) in content.custom.flexReply"
          :key="i"
          class="msg_wrap"
        >
          <div class="img_upload">
            <div
              class="img_upload-display"
              v-bind:style="{
                backgroundImage: 'url(' + content.imageUrl + ')'
              }"
            ></div>
          </div>

          <!-- <span class="font-weight-bold">เสื้อยืดผู้หญิง</span><br />
          <span class="font-weight-bold">฿1,100.00</span><br /> -->
          <span>{{ content.text }}</span>
          <hr />
          <button
            v-for="(btn, j) in content.button"
            type="button"
            class="btn btn-light w-100 mb-1 font-btn"
            :key="j"
          >
            <span class="font-btn">{{ btn.label }}</span>
          </button>
        </div>
      </VueSlickCarousel>
    </div>
    <div v-if="content.messageType == 7">
      <VueSlickCarousel v-bind="slickOptions">
        <div
          v-for="(content, i) in content.custom.image"
          :key="i"
          class="msg_wrap"
        >
          <div class="img_upload">
            <div
              class="img_upload-display"
              v-bind:style="{
                backgroundImage: 'url(' + content.imageUrl + ')'
              }"
              @click="handleOpenTab(content.link)"
            ></div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: {
    VueSlickCarousel
  },
  props: {
    con: {
      required: false,
      type: Object
    }
  },
  created() {
    this.content = this.con;
  },
  data() {
    return {
      content: {},
      slickOptions: {
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        dots: false,
        initialSlide: 0,
        autoplay: false
      },
      exTypeSix: {
        Mode: 2,
        TimeStamp: 1639480059561,
        ReplyToken: "c274cda9dddd498fabd9cd16cfb7c619",
        Message: {
          MessageType: 6,
          LanguageId: 1,
          MessageId: "1202112141807395517117720",
          Text:
            "ทดสอบจ้า\nhttps://dev-dbot-image.d3-dots.com/images/210214060254289.jpg\n -ทดสอบ \nทดสอบ2\nhttps://dev-dbot-image.d3-dots.com/images/210314060314340.jpg\n -ทดสอบ2 \n",
          Custom: {
            ReplyType: 6,
            FlexReply: [
              {
                ReplyType: 5,
                Text: "ทดสอบจ้า",
                ImageUrl:
                  "https://dev-dbot-image.d3-dots.com/images/210214060254289.jpg",
                Button: [{ Type: 1, Action: 3, Label: "ทดสอบ", Data: "ทดสอบ" }],
                LanguageId: 1
              },
              {
                ReplyType: 5,
                Text: "ทดสอบ2",
                ImageUrl:
                  "https://dev-dbot-image.d3-dots.com/images/210314060314340.jpg",
                Button: [
                  { Type: 1, Action: 3, Label: "ทดสอบ2", Data: "ทดสอบ2" }
                ],
                LanguageId: 1
              },
              {
                ReplyType: 5,
                Text: "ทดสอบ2",
                ImageUrl:
                  "https://dev-dbot-image.d3-dots.com/images/210314060314340.jpg",
                Button: [
                  { Type: 1, Action: 3, Label: "ทดสอบ2", Data: "ทดสอบ2" }
                ],
                LanguageId: 1
              }
            ],
            AltText: "ทดสอบจ้า",
            LanguageId: 1
          }
        },
        Source: {
          Sender: 2,
          UserGUID: "94da3426-2cfe-4886-a580-7a281b911975",
          ChannelId: "1656707475",
          BrandId: 1
        },
        PrechatInfo: null
      },
      exTypeSeven: {
        Mode: 2,
        TimeStamp: 1639480059786,
        ReplyToken: "c274cda9dddd498fabd9cd16cfb7c619",
        Message: {
          MessageType: 7,
          LanguageId: 1,
          MessageId: "1202112141807397862874851",
          Text:
            "https://dev-dbot-image.d3-dots.com/images/210314060338134.jpg \nhttps://dev-dbot-image.d3-dots.com/images/210414060409314.jpg \nhttps://dev-dbot-image.d3-dots.com/images/210414060414614.jpg \n",
          Custom: {
            ReplyType: 7,
            Image: [
              {
                ImageUrl:
                  "https://dev-dbot-image.d3-dots.com/images/210314060338134.jpg",
                Link: "https://www.youtube.com/"
              },
              {
                ImageUrl:
                  "https://dev-dbot-image.d3-dots.com/images/210414060409314.jpg",
                Link: "https://www.youtube.com/"
              },
              {
                ImageUrl:
                  "https://dev-dbot-image.d3-dots.com/images/210414060414614.jpg",
                Link: "https://www.youtube.com/"
              }
            ],
            AltText: "รูปภาพ",
            Label: "คลิก",
            LanguageId: 1
          }
        },
        Source: {
          Sender: 2,
          UserGUID: "94da3426-2cfe-4886-a580-7a281b911975",
          ChannelId: "1656707475",
          BrandId: 1
        },
        PrechatInfo: null
      },
      exCon: {
        MessageId: "msg_21625040449818",
        UserGUID: "8882e21a-2afc-4eeb-be20-65edf2a3423d",
        BrandId: 1,
        PlatformId: 1,
        CreatedTime: "2021-06-28T23:50:36+07:00",
        AdminMode: false,
        Message: {
          ReplyType: 6,
          FlexReply: [
            {
              ReplyType: 5,
              Text: "Test Flex",
              ImageUrl:
                "https://api-dev-brandadmin.dosetech.co/productImage/211711051719190.png",
              Button: [
                {
                  Type: 1,
                  Action: 1,
                  Label: "สวัสดีจ้าา",
                  Data: "สวัสดี"
                },
                {
                  Type: 1,
                  Action: 2,
                  Label: "สอบถามสินค้า",
                  Data: "สอบถาม"
                },
                {
                  Type: 1,
                  Action: 3,
                  Label: "กดเลย กดเลย",
                  Data: "https://dev-cmg-seller.dosetech.co"
                }
              ]
            },
            {
              ReplyType: 5,
              Text: "Test Flex",
              ImageUrl:
                "https://api-dev-brandadmin.dosetech.co/productImage/211711051719190.png",
              Button: [
                {
                  Type: 1,
                  Action: 1,
                  Label: "สวัสดีจ้าา",
                  Data: "สวัสดี"
                },
                {
                  Type: 1,
                  Action: 2,
                  Label: "สอบถามสินค้า",
                  Data: "สอบถาม"
                },
                {
                  Type: 1,
                  Action: 3,
                  Label: "กดเลย กดเลย",
                  Data: "https://dev-cmg-seller.dosetech.co"
                }
              ]
            }
          ],
          AltText: "altText"
        }
      }
    };
  },
  methods: {
    handleOpenTab(url) {
      window.open(url, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
div .img_upload {
  margin-left: auto;
  height: auto;
  max-height: 20rem;
  width: 100%;
  max-width: 30rem;
}
div .img_upload .img_upload-display {
  width: 100%;
  padding-bottom: 56.25%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: inherit !important;
}
.font-btn {
  color: #9a161f;
  font-weight: bold;
}
/deep/ .slick-prev:before,
/deep/ .slick-next:before {
  color: white;
  font-size: 25px;
  opacity: 1;
}
/deep/ .slick-next {
  margin-right: 5px;
}
/deep/ .slick-prev.slick-disabled:before,
/deep/ .slick-next.slick-disabled:before {
  opacity: 0.25;
}
</style>
