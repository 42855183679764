var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.messageList),function(con,i){return _c('div',{key:i},[(con.userType == 'system')?_c('div',{staticClass:"px-2 mb-3"},[_c('div',{staticClass:"separator"},[_vm._v(" "+_vm._s(con.message)+" ")])]):_vm._e(),(con.userType == 'admin')?_c('div',{staticClass:"d-flex justify-content-end mb-4"},[_c('div',{class:[
          con.messageType == 4 ? 'msg_cotainer_send_img' : 'msg_cotainer_send'
        ]},[(con.messageType == 1)?_c('span',{staticClass:"text-nextline"},[_vm._v(_vm._s(con.message))]):_vm._e(),(con.messageType == 4)?_c('div',[_c('div',{staticClass:"img_upload"},[_c('div',{staticClass:"img_upload-display",style:({
                backgroundImage: 'url(' + con.filePath + ')'
              })})])]):_vm._e(),(con.messageType != 1 && con.messageType != 4)?_c('div',[_c('ChatBoxWidget',{attrs:{"con":con}})],1):_vm._e(),_c('span',{staticClass:"msg_time_send text-nowrap"},[_c('ChatDateTime',{attrs:{"date":con.createdTime}})],1)])]):(con.userType == 'user')?_c('div',{staticClass:"d-flex justify-content-start mb-4"},[_c('div',{staticClass:"img_cont_msg"},[(_vm.otherInfo.imageUrl && _vm.otherInfo.imageUrl.trim())?_c('div',{staticClass:"rounded-circle user_img_msg"},[_c('div',{staticClass:"rounded-circle user_img_display",style:({
              backgroundImage: 'url(' + _vm.otherInfo.imageUrl + ')'
            })})]):_c('font-awesome-icon',{staticClass:"rounded-circle user_img_msg p-1",attrs:{"icon":['fas', 'user']}})],1),_c('div',{class:[con.messageType == 4 ? 'msg_cotainer_img' : 'msg_cotainer']},[(con.messageType == 1)?_c('span',{staticClass:"text-nextline"},[_vm._v(_vm._s(con.message))]):_vm._e(),(con.messageType == 4)?_c('div',{staticClass:"img_upload"},[_c('div',{staticClass:"img_upload-display",style:({
              backgroundImage: 'url(' + con.filePath + ')'
            })})]):_vm._e(),(con.messageType != 1 && con.messageType != 4)?_c('div',[_c('ChatBoxWidget',{attrs:{"con":con}})],1):_vm._e(),_c('span',{staticClass:"msg_time text-nowrap"},[_c('ChatDateTime',{attrs:{"date":con.createdTime}})],1)])]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }