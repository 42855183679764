<template>
  <div>
    <div v-for="(con, i) in messageList" :key="i">
      <!-- is Message From System -->
      <div v-if="con.userType == 'system'" class="px-2 mb-3">
        <div class="separator">
          {{ con.message }}
        </div>
      </div>
      <!-- <div class="px-2 mb-3">
        <div class="">
          {{ con.message }}
        </div>
      </div> -->
      <!-- is Text From Me -->
      <!-- v-else-if="con.isAdmin || con.isDummy" -->
      <div
        v-if="con.userType == 'admin'"
        class="d-flex justify-content-end mb-4"
      >
        <!-- <div v-if="con.isDummy" class="my-auto pr-2 pl-3">
          <div class="spinner-grow text-secondary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div> -->

        <div
          :class="[
            con.messageType == 4 ? 'msg_cotainer_send_img' : 'msg_cotainer_send'
          ]"
        >
          <span v-if="con.messageType == 1" class="text-nextline">{{
            con.message
          }}</span>
          <div v-if="con.messageType == 4">
            <div class="img_upload">
              <div
                class="img_upload-display"
                v-bind:style="{
                  backgroundImage: 'url(' + con.filePath + ')'
                }"
              ></div>
            </div>
          </div>
          <!-- Other Type -->
          <div v-if="con.messageType != 1 && con.messageType != 4">
            <ChatBoxWidget :con="con" />
          </div>

          <!-- con.messageResponse.content.responseType == 8 ? 'extent-width' : '' -->
          <!-- <div
            v-if="
              con.messageResponse.content.responseType == 6 ||
                con.messageResponse.content.responseType == 2
            "
          >
            <div
              v-for="(item, i) in con.messageResponse.content.imageList"
              :key="i"
              class="img_upload"
            >
              <div
                class="img_upload-display"
                v-bind:style="{
                  backgroundImage: 'url(' + item.imageUrl + ')'
                }"
              ></div>
            </div>
            <div
              v-if="con.messageResponse.content.responseType == 2"
              class="text-right"
            >
              <span
                class="ml-auto bg-white text-dark p-2 mt-1 rounded text-black"
                >{{ con.messageResponse.content.text }}
              </span>
            </div>
          </div> -->
          <!-- <ChatBoxWidget :con="con" v-else /> -->
          <span class="msg_time_send text-nowrap">
            <ChatDateTime :date="con.createdTime" />
          </span>
        </div>
      </div>
      <!-- is Text From Other -->
      <div
        v-else-if="con.userType == 'user'"
        class="d-flex justify-content-start mb-4"
      >
        <div class="img_cont_msg">
          <div
            v-if="otherInfo.imageUrl && otherInfo.imageUrl.trim()"
            class="rounded-circle user_img_msg"
          >
            <div
              class="rounded-circle user_img_display"
              v-bind:style="{
                backgroundImage: 'url(' + otherInfo.imageUrl + ')'
              }"
            ></div>
          </div>
          <font-awesome-icon
            v-else
            :icon="['fas', 'user']"
            class="rounded-circle user_img_msg p-1"
          />
        </div>
        <div
          :class="[con.messageType == 4 ? 'msg_cotainer_img' : 'msg_cotainer']"
        >
          <span v-if="con.messageType == 1" class="text-nextline">{{
            con.message
          }}</span>
          <div v-if="con.messageType == 4" class="img_upload">
            <div
              class="img_upload-display"
              v-bind:style="{
                backgroundImage: 'url(' + con.filePath + ')'
              }"
            ></div>
          </div>
          <!-- Other Type -->
          <div v-if="con.messageType != 1 && con.messageType != 4">
            <ChatBoxWidget :con="con" />
          </div>

          <!-- <div
            v-if="
              con.messageResponse.content.responseType == 6 ||
                con.messageResponse.content.responseType == 2
            "
          >
            <div
              v-for="(item, i) in con.messageResponse.content.imageList"
              :key="i"
              class="img_upload"
            >
              <div
                class="img_upload-display"
                v-bind:style="{
                  backgroundImage: 'url(' + item.imageUrl + ')'
                }"
              ></div>
            </div>
            <div class="text-left">
              <span
                class="mr-auto bg-white text-dark p-2 mt-1 rounded text-black"
                >{{ con.messageResponse.content.text }}
              </span>
            </div>
          </div>
          <ChatBoxWidget :con="con" v-else /> -->
          <span class="msg_time text-nowrap">
            <ChatDateTime :date="con.createdTime" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatDateTime from "@/components/chat/ChatDateTime";
import ChatBoxWidget from "@/components/chat/chatBox/ChatBoxWidget";
export default {
  name: "ChatBoxMessageList",
  components: {
    ChatDateTime,
    ChatBoxWidget
  },
  props: {
    messageList: {
      required: true,
      type: Array
    },
    meProfile: {
      required: true,
      type: Object
    },
    otherInfo: {
      required: true,
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
.msg_cotainer_send_img {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  padding: 2px 10px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 80%;
}
.msg_cotainer_send_img div {
  display: flex;
  width: 100%;
  flex-flow: column;
}
.msg_cotainer_send_img div .img_upload {
  margin-left: auto;
  height: auto;
  max-height: 20rem;
  width: 100%;
  max-width: 30rem;
}
.msg_cotainer_send_img div .img_upload .img_upload-display {
  width: 100%;
  padding-bottom: 56.25%;
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
  background-color: inherit !important;
}
.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  padding: 10px;
  position: relative;
  max-width: 82%;
  min-width: 25%;
  font-size: 14px;
}
.extent-width {
  width: 70% !important;
}
.spinner-grow {
  width: 20px;
  height: 20px;
}
.msg_wrap {
  white-space: pre-line;
  overflow-wrap: break-word;
}
.msg_time_send {
  position: absolute;
  right: 0;
  bottom: -15px;
  color: #dbdbdb;
  font-size: 10px;
}
.msg_cotainer_send_img .msg_time_send {
  padding-right: 10px;
}
.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}
.user_img_display {
  width: 100%;
  padding-bottom: 100.5%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}
.msg_cotainer_img {
  margin-top: auto;
  margin-bottom: auto;
  // margin-left: auto;
  padding: 2px 10px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 80%;
}
.msg_cotainer_img div {
  display: flex;
  width: 100%;
  flex-flow: column;
}
.msg_cotainer_img .img_upload {
  margin-right: auto;
  height: auto;
  max-height: 20rem;
  width: 100%;
  max-width: 30rem;
}
.msg_cotainer_img .img_upload .img_upload-display {
  width: 100%;
  padding-bottom: 56.25%;
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  background-color: inherit !important;
}
.img_cont_msg {
  height: 40px;
  width: 40px;
}
.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 4px;
  background-color: #b3fffd;
  padding: 10px;
  position: relative;
  max-width: 82%;
  min-width: 25%;
  font-size: 14px;
}
.msg_time {
  position: absolute;
  left: 0;
  bottom: -15px;
  color: #dbdbdb;
  font-size: 10px;
}
.msg_cotainer_img .msg_time {
  padding-left: 10px;
}
.loading-dummy-img {
  position: absolute;
  width: 20%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  font-size: 14px;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #cccccc;
}

.separator:not(:empty)::before {
  margin-right: 0.4em;
}

.separator:not(:empty)::after {
  margin-left: 0.4em;
}

.text-black {
  color: #000 !important;
}
.text-nextline {
  white-space: pre-line;
}
@media (max-width: 767.98px) {
}
</style>
